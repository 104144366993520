import React from 'react'

let url              			= process.env.PUBLIC_URL;

/* ---> Live Server --*/
let redirect_url             	= 'https://specials.target-healthcare.co.uk'

/* ---> Test Server --*/
//let redirect_url             	= 'https://specials-test.target-healthcare.co.uk'

let route 						= '/'

export function RouteURL(props) {
	return route+props;
}

export function WindowReload(props){
   return window.location.reload();
}

export function WindowURL(props) {
	return url+props;
}


export function RedirectURL(props) {
	return redirect_url+props;
}


export function WindowLocation(props) {
	return window.location.href = url+props;
}

export function ActiveMenu(props){
   let path = window.location.pathname;
   var url=path.split('/');
   const found = url.find(element => element === props);
   if(found==props){
     return true;
   }else{
		 return false;
   }
}

export function HomeActiveMenu(props){
	 let path = window.location.pathname;
	 var url  = path.split('/');
   if(url[3]==props){
		 return true;
	 }else{
		 return false;
	 }
}
